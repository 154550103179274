<template>
  <div>
    <b-row class="match-height">
      <b-col
        cols="12"
        md="3"
        class="mb-2"
      >
        <div class="border rounded p-2 text-center">
          <div class="text-primary font-weight-bold font-medium-2">
            İş Emri No
          </div>
          <div class="font-medium-2 font-weight-light">
            {{ dataItem.work_no }}
          </div>
        </div>
      </b-col>
      <b-col
        cols="12"
        md="3"
        class="mb-2"
      >
        <div class="border rounded p-2 text-center">
          <div class="text-primary font-weight-bold font-medium-2">
            İş Tarihi
          </div>
          <div class="font-medium-2 font-weight-light">
            {{ moment(dataItem.wdate).format('DD.MM.YYYY') }}
          </div>
        </div>
      </b-col>
      <b-col
        cols="12"
        md="3"
        class="mb-2"
      >
        <div class="border rounded p-2 text-center">
          <div class="text-primary font-weight-bold font-medium-2">
            Bölüm
          </div>
          <div class="font-medium-2 font-weight-light">
            {{ dataItem.service_section }}
          </div>
        </div>
      </b-col>
      <b-col
        cols="12"
        md="3"
        class="mb-2"
      >
        <div class="border rounded p-2 text-center">
          <div class="text-primary font-weight-bold font-medium-2">
            Yapılacak İşlem
          </div>
          <div class="font-medium-2 font-weight-light">
            {{ dataItem.service_work }}
          </div>
        </div>
      </b-col>
      <b-col
        cols="12"
        md="4"
        class="mb-2"
      >
        <div class="border rounded p-2 text-center">
          <div class="text-primary font-weight-bold font-medium-2">
            İşlem Tipi
          </div>
          <div class="font-medium-2 font-weight-light">
            <span v-if="dataItem.is_da">S&T</span>
            <span v-if="dataItem.is_repair">Onarım</span>
            <span v-if="dataItem.is_change">Değişim</span>
            <span v-if="dataItem.is_work">Diğer</span>
          </div>
        </div>
      </b-col>
      <b-col
        cols="12"
        md="4"
        class="mb-2"
      >
        <div class="border rounded p-2 text-center">
          <div class="text-primary font-weight-bold font-medium-2">
            Standart Süre
          </div>
          <div class="font-medium-2 font-weight-light">
            <div v-if="(dataItem.target_min / 60) >= 1">
              {{ dataItem.target_min / 60 }} Saat
            </div>
            <div v-else>
              {{ dataItem.target_min }} Dakika
            </div>
          </div>
        </div>
      </b-col>
      <b-col
        cols="12"
        md="4"
        class="mb-2"
      >
        <div class="border rounded p-2 text-center">
          <div class="text-primary font-weight-bold font-medium-2">
            Toplam Harcanan Süre
          </div>
          <div class="font-medium-2 font-weight-light">
            <div>
              {{ moment.utc(moment.duration(dataItem.duration * 1000).asMilliseconds()).format('HH:mm') }}
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'

export default {
  name: 'WorkOrder',
  components: {
    BRow,
    BCol,
  },
  props: {
    dataItem: {
      type: Object,
      required: true,
    },
  },
}
</script>
